'use strict';

export default FWTK('utilities', 'ajax', 'components', 'listing', function (fw) {
  const RC = fw.listing;
  const BTN_HANDLER = RC.BTN_HANDLER;
  let ERROR_TXT;
  let SUCCESS_TXT;
  let URL;
  let PAGES = {};

  PAGES['login'] = function () {
    let container = $('#forgotpw_container');

    URL = '/password/forgot/';
    ERROR_TXT = $('.error_txt');
    SUCCESS_TXT = $('.success_txt');

    $('#forgot_password_btn').click(function (e) {
      submitRequest(
        e,
        {
          email: $('#email').val(),
          forgot_password: 1
        },
        this
      );

      return false;
    });

    $('#forgot_password_link').click(function (e) {
      e.preventDefault();
      if (!container.is(':visible')) {
        container.fadeIn();
      } else {
        container.fadeOut();
      }
    });
  };

  PAGES['forgotPassword'] = function () {
    fw.components('passwordstrength');

    $('#password1, #password2').val('');

    URL = '/password/update/';
    ERROR_TXT = $('.errormsg');
    SUCCESS_TXT = $('.successmsg');

    $('#submit').click(function (e) {
      submitRequest(
        e,
        {
          password1: $('#password1').val(),
          password2: $('#password2').val(),
          token: $('#token').val()
        },
        this
      );

      return false;
    });

    fw.passwordstrength({
      template: '',
      ids: {
        /**
         * Theses are the ids or class for the components for the password
         * strength test. Look at the jquery.passwordstrength for defaults.
         */
        input: '#password1',
        text: '#strength_header',
        meter: '#strength_meter'
      },
      default_message: 'Your password must be at least 4 characters.',
      minimum_length: 4,
      scores: [
        {score: 24, text: 'Weak', color: '#df3a3a'},
        {score: 48, text: 'Fair', color: '#df753a'},
        {score: 72, text: 'Good', color: '#f0a618'},
        {score: 96, text: 'Strong', color: '#85c12f'}
      ],
      rules: {
        minimum_score: 7
      },
      resetCallback: function () {
        $('#strength-header').hide();
        $('#strength-meter li').removeAttr('class');
      },
      passCallback: function (score, styling) {
        $('#strength-meter li').removeAttr('class');

        if (typeof score == 'undefined') {
          return;
        }

        $('#strength-header')
          .html(`Strength: <span class="${styling.text.toLowerCase()}">${styling.text}</span>`)
          .show();

        let count = 0;

        switch (styling.text) {
          case 'Weak':
            count = 1;
            break;
          case 'Fair':
            count = 2;
            break
          case 'Good':
            count = 3;
            break;
          case 'Strong':
            count = 4;
            break;
        }

        $('#strength-meter li').each(function (index, element) {
          if (count > index) {
            $(element)
              .removeClass('weak fair good strong')
              .addClass(styling.text.toLowerCase());
          }
        });
      }
    });
  };

  function submitRequest(e, data, obj) {
    e.preventDefault();
    let id = '#' + obj.id;

    if (BTN_HANDLER.isLocked(id)) {
      return;
    }

    RC.disableButtons(id);
    RC.handleAjax(
      {
        info: {
          data: data,
          url: URL,
          type: 'POST'
        },
        success: function (result) {
          RC.enableButtons(id);

          if (result && result.success) {
            if ($('body').is('[data-redirect-url]')) {
              document.location = $('body').attr('data-redirect-url');
              return;
            }

            SUCCESS_TXT.fadeIn();
            ERROR_TXT.fadeOut();
          } else {
            if (typeof result.errors != "undefined") {
              ERROR_TXT.html(result.errors);
            }

            ERROR_TXT.fadeIn();
            SUCCESS_TXT.fadeOut();
          }
        },
        buttons: [id]
      }
    );

    return false;
  };

  RC.loadPage(PAGES);
});